<template>
	<div class="settled-wrapper content">
		<div class="user-com-title">
			入驻申请
		</div>
		<div class="settled-main">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
				@submit.native.prevent>
				<el-form-item label="商户名称：" prop="name">
					<el-input v-model="ruleForm.name" placeholder="请输入商户名称"></el-input>
				</el-form-item>
				<el-form-item label="用户姓名：" prop="username">
					<el-input v-model="ruleForm.username" placeholder="请输入用户姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号：" prop="phone">
					<el-input v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="验证码：" prop="yanzhengma">
					<el-input v-model="ruleForm.yanzhengma" placeholder="请输入验证码" class="verifiCode"></el-input>
					<button class="code font-color" :disabled="disabled" :class="disabled === true ? 'on' : ''"
						@click="getVerify">
						{{ text }}
					</button>
				</el-form-item>
				<el-form-item label="商户分类：" prop="classification">
					<el-select v-model="ruleForm.classification" placeholder="请选择商户分类">
						<el-option :label="item.category_name" :value="item.merchant_category_id"
							v-for="(item, index) in array" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="店铺类型：" prop="type">
					<el-select v-model="ruleForm.type" placeholder="请选择店铺类型">
						<el-option :label="item.type_name" :value="item.mer_type_id" v-for="(item, index) in typeArray"
							:key="index"></el-option>
					</el-select>
					<el-tooltip class="item" effect="dark" :content="storeContent" placement="right">
						<span class="iconfont wenhao">?</span>
					</el-tooltip>
				</el-form-item>
				<el-form-item label="营业执照：">
					<div class="text">
						请上传营业执照及行业相关资质证明图片
						<span class="text-desc">(图片最多可上传10张， 单张图片不超过2M， 图片格式支持JPG、PNG、JPEG)</span>
					</div>
					<el-upload :action="action" :headers="headers" list-type="picture-card" :limit="currentLimit" accept="image/*"
						:before-upload="beforeAvatarUpload" :on-success="handleSuccess" :on-remove="handleRemove"
						:on-exceed="handleExceed">
						<i slot="default" class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item>
				<div>
					<el-checkbox v-model="checked">已阅读并同意</el-checkbox>
					<span class="agreement" @click="getAgreement">《入驻协议》</span>
				</div>
				<div style="text-align: center;margin-top:20px;">
					<el-button type="primary" @click="onSubmit">提交申请</el-button>
				</div>
			</el-form>
		</div>
		<!--入驻协议-->
		<div v-if="showProtocal" class="protocolModal" @click.stop="showProtocal=false">
			<div class="protocolCount">
				<div class="protocolMain">
					<div class="title">{{title}}</div>
					<div class="content">
						<div class="content-main" v-html="agreement"></div>
					</div>
					<div class="sureBbtn" v-if="isSettled">
						<el-button type="primary" @click="showProtocal=false;checked=true;">同意并继续</el-button>
					</div>
					<div class="sureBbtn" v-else>
						<el-button type="primary" @click="showProtocal=false;">我知道了</el-button>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import { VUE_APP_API_URL } from '@utils';
	import sendVerifyCode from '../mixins/SendVerifyCode';
	import {
		getArticleDetails
	} from '@api/user';
	export default {
		name: "merchantSettled",
		mixins: [sendVerifyCode],
		data() {
			var checkphone = (rule, value, callback) => {
				// let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
				if (value == "") {
					callback(new Error("请输入手机号"));
				} else if (!this.isCellPhone(value)) { //引入methods中封装的检查手机格式的方法
					callback(new Error("请输入正确的手机号!"));
				} else {
					callback();
				}
			};
			return {
				action: `${VUE_APP_API_URL}/upload/image`,
				headers: {
					'Authori-zation': 'Bearer ' + window.sessionStorage.getItem('token')
				},
				agreement: '',
				title: '',
				isHidden: false,
				showProtocal: false,
				isSettled: false,
				checked: false,
				array: [{
					merchant_category_id: 0,
					category_name: '美妆护肤'
				}, {
					merchant_category_id: 1,
					category_name: '手表配饰'
				}, {
					merchant_category_id: 2,
					category_name: '箱包手袋'
				}],
				typeArray: [{
					mer_type_id: 0,
					type_name: '自营店铺'
				}, {
					mer_type_id: 1,
					type_name: '厂家直销'
				}, {
					mer_type_id: 2,
					type_name: '旗舰店'
				}],
				storeContent: "设置店铺类型，并根据不同店铺类型，设置店铺保证金与店铺权限，方便平台针对不同类店铺多样化运营。",
				dialogVisible: false,
				dialogImageUrl: '',
				currentLimit: 10,
				ruleForm: {
					name: '',
					username: '',
					phone: '',
					yanzhengma: '',
					classification: '',
					type: '',
					images: []
				},
				rules: {
					name: [{
							required: true,
							message: '请输入商户名称',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 15,
							message: '长度在 3 到 15 个字符',
							trigger: 'blur'
						}
					],
					username: [{
							required: true,
							message: '请输入用户姓名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 5,
							message: '长度在 2 到 5 个字符',
							trigger: 'blur'
						}
					],
					yanzhengma: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入手机号',
						validator: checkphone,
						trigger: 'blur'
					}],
					classification: [{
						required: true,
						message: '请选择商户分类',
						trigger: 'change'
					}],
					type: [{
						required: true,
						message: '请选择店铺类型',
						trigger: 'change'
					}]
				}
			}
		},
		methods: {
			//检查手机号
			isCellPhone(val) {
				if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
					return false;
				} else {
					return true;
				}
			},
			getVerify() {
				let that = this;
				if (!that.ruleForm.phone) return that.$message.error('请填写手机号码');
				if (!/^1(3|4|5|7|8|9|6)\d{9}$/i.test(that.ruleForm.phone)) return that.$message.error('请输入正确的手机号码');
				that.sendCode();
			},
			//上传图片前的图片验证回调
			beforeAvatarUpload(file) {
				//图片格式
				const isJPG = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
				//图片大小
				const isLt2M = file.size / 1024 / 1024 <= 2;
				if (!isJPG) {
					this.$message.error('上传图片只能为jpg/jpeg或png格式');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过2MB');
				}
				const _this = this;
				return isJPG && isLt2M;
			},
			//文件超出个数限制时的钩子
			handleExceed() {
				this.$message.warning("最多上传10张图片");
			},
			//文件列表移除文件时的钩子
			handleRemove(file, fileList) {
				this.pics = [];
				fileList.forEach(item => {
					this.pics.push(item.response.data.url);
				});
				this.currentLimit = this.uploadLimit - this.pics.length - this.imgUrl.length || -1;
			},
			//文件上传成功时的钩子
			handleSuccess(response) {
				if (response.status === 200) {
					this.pics.push(response.data.path);
				} else if (response.status === 400) {
					this.$message.error(response.msg);
				}
			},
			//获取入驻协议内容
			getAgreement() {
				let that = this;
				getArticleDetails('44').then(res => {
					that.$nextTick(() => {
						that.agreement = res.data.content;
						that.title = '入驻协议'
						that.showProtocal = true
						that.isSettled = true;
					})

				}).catch(err => {
					that.$message.error(err);
				});
			},
			onSubmit() {
				let that = this;
			}
		}
	}
</script>

<style lang="less" scoped>
	.settled-wrapper {
		background: #fff;
		padding: 0 91px 44px 109px;

		.user-com-title {
			font-size: 20px;
			color: #282828;
			text-align: center;
			line-height: 30px;
			border-bottom: 1px solid #EFEFEF;
		}

		.demo-ruleForm {
			margin-top: 30px;

			.el-form-item {
				margin-bottom: 34px;
			}

			.el-input {
				width: 330px;
				height: 36px;
				line-height: 36px;
			}

			.verifiCode {
				width: 200px;

			}

			.code {
				width: 96px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				color: #E93323;
				border: 1px solid #E93323;
				border-radius: 4px;
				margin-left: 14px;
				background: #fff;
			}

			.el-form-item__label,
			.text {
				color: #666666;
			}

			/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
				color: #666666;
			}

			.text-desc {
				font-size: 12px;
				color: #999999;
			}
		}

		.el-form-item {
			position: relative;
		}

		.wenhao {
			display: inline-block;
			text-align: center;
			width: 17px;
			height: 17px;
			line-height: 17px;
			font-size: 14px;
			border-radius: 50%;
			background: #E3E3E3;
			color: #ffffff;
			margin-left: 4px;
			cursor: pointer;
		}

		.agreement {
			color: #E93323;
			font-family: 'PingFang SC';
			cursor: pointer;
		}
	}

	.hide .el-upload--picture-card {
		display: none;
	}

	.protocolModal {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;

		.protocolCount {
			width: 900px;
			height: 800px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -350px;
			margin-left: -450px;

			.protocolMain {
				width: 100%;
				height: 710px;
				background: #fff;
				border-radius: 6px;
				padding: 0 32px;

				.title {
					padding: 20px 0;
					text-align: center;
					color: #333333;
					font-size: 20px;
					font-weight: bold;
				}

				.content-main {
					color: #333;
					line-height: 20px;
					overflow-y: auto;
					height: 500px;
				}
			}
		}

		.sureBbtn {
			text-align: center;
			margin-top: 60px;

			.el-button {
				width: 180px;
				height: 46px;
			}
		}
	}
</style>